// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap/dist/js/bootstrap.bundle.min')
require('admin-lte')
require('admin-lte/plugins/daterangepicker/daterangepicker.js')
require('admin-lte/plugins/select2/js/select2.full.js')

import "@fortawesome/fontawesome-free/js/all"
import Swal from 'sweetalert2'

const jQuery = require('jquery')
window.$ = jQuery
window.jQuery = jQuery

require('jquery-sticky')
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$.fn.torstr = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
});

(function ($) {
  $.fn.initSingleDateTimePicker = function(_target) {
    $(_target).daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      timePicker: true,
      autoApply: true,
      timePicker24Hour: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm',
      },
    });

    $(_target).on('apply.daterangepicker', function(_event, picker) {
      $(this).val(picker.startDate.format(picker.locale.format));
    });

    $(_target).on('cancel.daterangepicker', function(_eventev, _picker) {
      $(this).val("");
    });
  };

  $.fn.initSingleDatePicker = function(_target, callback) {
    $(_target).daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY',
      },
    });

    $(_target).on('apply.daterangepicker', function(_event, picker) {
      $(this).val(picker.startDate.format(picker.locale.format));

      if (typeof(callback) === 'function') {
        callback(_event);
      }
    });

    $(_target).on('cancel.daterangepicker', function(_eventev, _picker) {
      $(this).val("");
    });
  }

  $.fn.tableHighlighter = function(id) {
    let $el = $(id);
    $el.addClass("table-warning");
    $el.siblings().removeClass("table-warning");
  };

  $.fn.handleRemoveCard = function() {
    $('.remove-card-btn').on('click', function() {
      $(this).closest('.card').remove();
    })
  }

})(jQuery);
